import * as React from "react";
import { Layout } from "../../components/Layout";
import ReactHtmlParser from "react-html-parser";
import { FaFacebook } from "@react-icons/all-files/fa/FaFacebook";
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import moment from "moment";
import { graphql } from "gatsby";
import SEO from "../../components/seo";
import styled from "styled-components"

const StyledContent = styled.section`
  margin-left: 15%;
  margin-right: 15%;
  color: white;
  display: block;

  @media (max-width: 425px) {
    margin-left: 10%;
    margin-right: 10%;
  }

  img {
    max-width: 100%;
    border-radius: 8px;
    vertical-align: top;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  h2 {
    padding: 0;
    margin: .5rem 0 1.45rem;
    font-weight: bold;
    font-size: 1.62671rem;
  }
`

const StyledImage = styled.img`
  border-radius: 8px;
  vertical-align: top;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
`

const Post = ({ data }) => {
  const post = data.ghostPost;

  console.log(data, "9");
  return (
    <div style={{ backgroundColor: `black`, color: `white` }}>
      <Layout>
        <SEO
          title={`${post.title}`}
          image={`${post.feature_image}`}
          description={`${post.meta_description}`}
          url={`https://www.zector.io/${post.slug}`}
        />
        <div className="main-blog-post">
          <h1 className="main-title" style={{fontFamily: `Montserrat, sans-serif`}} style={{fontFamily: `Montserrat, sans-serif`}}>{data.ghostPost.title}</h1>

          <div className="d-flex auth-blog">
            <div>
              <StyledImage
                src={data.ghostPost.feature_image}
                alt={data.ghostPost.title}
                className="author-image"
                height="800"
                width="1000"
              />
            </div>
            <div className="auth-right-det">
              <span style={{fontFamily: `Montserrat, sans-serif`}}>{data.ghostPost.authors[0].name}</span>

              <p style={{fontFamily: `Montserrat, sans-serif`}}>
                {moment(data.ghostPost.published_at).format("MMMM DD, YYYY")}
              </p>
            </div>
          </div>
          <div className="blog-image-wrap">
            <img
              src={data.ghostPost.feature_image}
              alt={data.ghostPost.title}
              className="blog-image"
            />
          </div>
          <StyledContent dangerouslySetInnerHTML={{ __html: data.ghostPost.html }} style={{fontFamily: `Montserrat, sans-serif`, color: `white`}} />
          <div className="social-link-box d-flex">
            <div className="d-flex social-link-box-inner">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.facebook.com/sharer.php?u=http://www.zector.io/${post.slug}`}
                style={{color: `#0C8BF0`}}
                >
                <FaFacebook className="socho-icon" />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`http://twitter.com/share?text=${encodeURIComponent(
                  post.title
                )}&url=https://www.zector.io/${post.slug}`}
                style={{color: `#1FA1F1`}}
              >
                <FaTwitter className="socho-icon" />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.linkedin.com/shareArticle?mini=true&url=https://www.zector.io/${
                  post.slug
                }&title=${encodeURIComponent(
                  post.title
                )}&source=https://zector.io/`}
                style={{color: `#0177B7`}}
              >
                <FaLinkedin className="socho-icon" />
              </a>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};
export default Post;

export const postQuery = graphql`
  query($slug: String!) {
    ghostPost(slug: { eq: $slug }) {
      title
      slug
      feature_image
      html
      authors {
        name
      }
    }
  }
`;
